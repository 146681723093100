import * as React from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SiteContainer from "../../components/site-container";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";

export function Privacy() {
    return (
        <div>
            <Link to="/terms/">Privacy</Link>
        </div>
    )
}

const PrivacyPage = () => {
    return (
        <SiteContainer>
            <Helmet>
                <title>Firstparty - Privacy Policy</title>
                <link rel="icon" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🎉</text></svg>" />
            </Helmet>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8}>

                        <h1>Firstparty Privacy Policy</h1>
                        <h4><em>Effective as of October 1, 2021</em></h4>
                        <br></br>
                        <p>This Privacy Policy describes the privacy practices of First Party Labs, Inc. ("Firstparty," "we", “us” or "our") and how we handle personal information that we collect through our digital properties, including those that link to this Privacy Policy, (collectively, the “Service”), as well as through [social media,] or our marketing activities described in this Privacy Policy. </p>
                        <p>Firstparty may provide additional or supplemental privacy policies to individuals for specific products or services that we offer at the time we collect personal information.</p>
                        <p>Firstparty provides a platform (the “Firstparty Platform”) that collects web and app analytics and stores them in our customers’ (“Customers”)  unique domain so that our Customers can view web traffic in connection with their end users (“End Users”). We collect information designated by our Customers, which may include personal information of our Customers’ End Users.  If you, as an End User, have concerns regarding personal information that we process on behalf of our Customers, please direct your concerns to the applicable Customer.</p>
                        <p>Our websites, the Firstparty Platform, and our products and services are designed for businesses and their representatives. We do not offer products or services for use by individuals for their personal, family or household purposes. Accordingly, we treat all personal information we collect as pertaining to individuals in their capacities as business representatives and not their individual capacities.</p>
                        <h2>Personal information we collect</h2>
                        <p><strong>Information you provide to us.</strong>  Personal information you may provide to us through the Service or may otherwise include:</p>
                        <ul>
                            <li>Contact data, such as your first and last name, email address, billing and mailing addresses, professional title and company name, and phone number.</li>
                            <li>Profile data, such as the username and password that you may set to establish an account with us in connection with the Firstparty Platform, and any other information that you add to your account profile. </li>
                            <li>Firstparty Platform Data, which includes information that our Customers’ upload to or through our Platform, without limitation, information pertaining to End Users of our Customers’ offerings, and information that our Customers direct us to collect from End Users.</li>
                            <li>Communications that we exchange with you, including when you contact us with questions or feedback, through the Service, social media, or otherwise. </li>
                            <li>Transactional data, such as information relating to or needed to complete transactions on or through the Service.</li>
                            <li>Payment information needed to complete transactions, including payment card information or bank account number.</li>
                            <li>Marketing data, such as your preferences for receiving our marketing communications and details about your engagement with them.</li>
                            <li>User-generated content, such as comments, questions, messages, works of authorship, and other content or information that you generate, transmit, or otherwise make available on the Service, as well as associated metadata.  Metadata includes information on how, when, where and by whom a piece of content was collected and how that content has been formatted or edited.  Metadata also includes information that users can add or can have added to their content, such as keywords, geographical or location information, and other similar data. </li>
                            <li>Other data not specifically listed here, which we will use as described in this Privacy Policy or as otherwise disclosed at the time of collection.</li>
                        </ul>
                        <p><strong>Automatic data collection.</strong>  We, our service providers, and our business partners may automatically log information about you, your computer or mobile device, and your interaction over time with the Service, our communications and other online services, such as:</p>
                        <ul>
                            <li>Device data, such as your computer’s or mobile device’s operating system type and version, manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP address, unique identifiers (including identifiers used for advertising purposes), language settings, mobile device carrier, radio/network information (e.g., Wi-Fi, LTE, 3G), and general location information such as city, state or geographic area.</li>
                            <li>Online activity data, such as pages or screens you viewed on our Service, how long you spent on a page or screen, the website you visited before browsing to our website, navigation paths between pages or screens, information about your activity on a page or screen, access times and duration of access to our Service, and whether you have opened our marketing emails or clicked links within them.</li>
                        </ul>
                        <p>Cookies and similar technologies. Like many online services, we use the following technologies in connection with our website:</p>
                        <ul>
                            <li>Cookies, which are text files that websites store on a visitor‘s device to uniquely identify the visitor’s browser or to store information or settings in the browser for the purpose of helping you navigate between pages efficiently, remembering your preferences, enabling functionality, helping us understand user activity and patterns, and facilitating analytics and online advertising.</li>
                            <li>Local storage technologies, like HTML5 and Flash, that provide cookie-equivalent functionality but can store larger amounts of data, including on your device outside of your browser in connection with specific applications. </li>
                            <li>Web beacons, also known as pixel tags or clear GIFs, which are used to demonstrate that a webpage or email address was accessed or opened, or that certain content was viewed or clicked.</li>
                        </ul>
                        <h2>How we use your personal information</h2>
                        <p>We may use your personal information for the following purposes or as otherwise described at the time of collection:</p>
                        <p>Service delivery.  We may use your personal information to:</p>
                        <ul>
                            <li>provide, operate and improve the Service and our business;</li>
                            <li>establish and maintain your user profile on the Service;</li>
                            <li>enable security features of the Service, such as by sending you security codes via email or SMS, and remembering devices from which you have previously logged in;</li>
                            <li>communicate with you about the Service, including by sending announcements, updates, security alerts, and support and administrative messages;</li>
                            <li>understand your needs and interests, and personalize your experience with the Service and our communications; and</li>
                            <li>provide support for the Service, and respond to your requests, questions and feedback.</li>
                        </ul>
                        <p>Research and development.  We may use your personal information for research and development purposes, including to analyze and improve the Service and our business. </p>
                        <p>Marketing.  We may send you Firstparty-related or other direct marketing communications as permitted by law.  You may opt-out of our marketing communications as described in the Opt-out of marketing section below. </p>
                        <p>Compliance and protection.  We may use your personal information to:</p>
                        <ul>
                            <li>comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities;</li>
                            <li>protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims); </li>
                            <li>audit our internal processes for compliance with legal and contractual requirements or our internal policies;</li>
                            <li>enforce the terms and conditions that govern the Service; and </li>
                            <li>prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal activity, including cyberattacks and identity theft.  </li>
                        </ul>
                        <p>With your consent.  In some cases, we may specifically ask for your consent to collect, use or share your personal information, such as when required by law.</p>
                        <p>To create anonymous, aggregated or de-identified data.  We may create anonymous, aggregated or de-identified data from your personal information and other individuals whose personal information we collect.  We make personal information into anonymous, aggregated or de-identified data by removing information that makes the data identifiable to you.  We may use this anonymous, aggregated or de-identified data and share it with third parties for our lawful business purposes, including to analyze and improve the Service and promote our business.  </p>
                        <h2>How we share your personal information</h2>
                        <p>We may share your personal information with the following parties and as otherwise described in this Privacy Policy or at the time of collection. </p>
                        <p>Affiliates.  Our current and future corporate parent, subsidiaries, and affiliates, for purposes consistent with this Privacy Policy.</p>
                        <p>Service providers.  Third parties that provide services on our behalf or help us operate the Service or our business (such as hosting, information technology, customer support, email delivery, and marketing). </p>
                        <p>Payment processors. Any payment card information you use to make a purchase on the Service is collected and processed directly by our payment processors, such as Stripe.  Stripe may use your payment data in accordance with its privacy policy, https://stripe.com/privacy.  You may also sign up to be billed by your mobile communications provider, who may use your payment data in accordance with their privacy policies.</p>
                        <p>Third parties designated by you. We may share your personal data with third parties where you have instructed us or provided your consent to do so. We will share personal information that is needed for these other companies to provide the services that you have requested. </p>
                        <p>Business and marketing partners. Third parties with whom we collaborate on joint activities, with whom we have entered into joint marketing relationships or other joint ventures, or who we think may offer you products or services that you may enjoy.</p>
                        <p>Professional advisors.  Professional advisors, such as lawyers, auditors, bankers and insurers, where necessary in the course of the professional services that they render to us.</p>
                        <p>Authorities and others. Law enforcement, government authorities, and private parties, as we believe in good faith to be necessary or appropriate for the compliance and protection purposes described above.</p>
                        <p>Business transferees.  Acquirers and other relevant participants in business transactions (or negotiations and diligence for such transactions) involving a corporate divestiture, merger, consolidation, acquisition, reorganization, sale or other disposition of all or any portion of the business or assets of, or equity interests in, Firstparty or our affiliates (including, in connection with a bankruptcy or similar proceedings).</p>
                        <h2>Your Choices</h2>
                        <p>You have the following choices with respect to your personal information.</p>
                        <p>Access or update your information. If you have registered for an account with us through the Service, you may review and update certain account information by logging into the account.</p>
                        <p>Opt-out of marketing communications.  You may opt-out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of the email, or by contacting us.  Please note that if you choose to opt-out of marketing-related emails, you may continue to receive service-related and other non-marketing emails.  </p>
                        <p>Do Not Track.  Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit.  We currently do not respond to “Do Not Track” or similar signals.  To find out more about “Do Not Track,” please visit http://www.allaboutdnt.com.</p>
                        <p>Declining to provide information. We need to collect personal information to provide certain services.  If you do not provide the information we identify as required or mandatory, we may not be able to provide those services.</p>
                        <h2>Other sites and services</h2>
                        <p>The Service may contain links to websites, mobile applications, and other online services operated by third parties.  In addition, our content may be integrated into web pages or other online services that are not associated with us.  These links and integrations are not an endorsement of, or representation that we are affiliated with, any third party.  We do not control websites, mobile applications or online services operated by third parties, and we are not responsible for their actions. We encourage you to read the privacy policies of the other websites and mobile applications and online services you use.</p>
                        <h2>Security</h2>
                        <p>We employ a number of technical, organizational and physical safeguards designed to protect the personal information we collect.  However, security risk is inherent in all internet and information technologies and we cannot guarantee the security of your personal information.</p>
                        <h2>International data transfer</h2>
                        <p>We are headquartered in the United States and may use service providers that operate in other countries. Your personal information may be transferred to the United States or other locations where privacy laws may not be as protective as those in your state, province, or country.  </p>
                        <h2>Children</h2>
                        <p>The Service is not intended for use by children under 13 years of age. If we learn that we have collected personal information through the Service from a child under 13 without the consent of the child’s parent or guardian as required by law, we will delete it. We encourage parents or guardians with concerns to contact us. </p>
                        <h2>Changes to this Privacy Policy</h2>
                        <p>We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on the website. If required by law we will also provide notification of changes in another way that we believe is reasonably likely to reach you, such as via email or another manner through the Service.  Any modifications to this Privacy Policy will be effective upon our posting the modified version (or as otherwise indicated at the time of posting). In all cases, your use of the Service after the effective date of any modified Privacy Policy indicates your acceptance of the modified Privacy Policy.</p>
                        <h2>How to contact us</h2>
                        <ul>
                            <li>Email: support@firstpartyhq.com</li>
                            <li>Mail: 4400 N Scottsdale Rd, Ste 9 #701, Scottsdale, AZ 85251</li>
                        </ul>
                    </Col>

                </Row>
            </Container>
        </SiteContainer>
    )
}

export default PrivacyPage
